import React, { useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import { Container } from 'react-bootstrap';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

import { GlobalDispatchContext } from '../../context/GlobalContextProvider';
import { ThemeDispatchContext } from '../../context/ThemeContextProvider';

import ExerciseList from '../../components/exerciseList/exerciseList';
import './water-exercises-post.scss';

const wpWaterExercisesPost = ({
  data: {
    wpWaterExercisesPost: {
      title, acfExerciseContent, seo, language, translations,
    },
  },
}) => {
  useEffect(() => {
    dispatch({ type: 'SET_TRANSLATION_LINKS', payload: { translationLinks: translations } });
    dispatch({ type: 'SET_LANG', payload: { lang: language } });
    dispatchTheme({ type: 'SET_THEME', payload: { themeName: 'water' } });
  }, []);

  const dispatch = useContext(GlobalDispatchContext);
  const dispatchTheme = useContext(ThemeDispatchContext);

  return (
    <Layout>
      <SEO
        title={title}
        meta={
        [
          { opengraphSiteName: seo.opengraphSiteName },
          { opengraphType: seo.opengraphType },
          { opengraphTitle: seo.opengraphTitle },
          { opengraphImage: seo.opengraphImage?.link },
          { imageHeight: seo.opengraphImage?.mediaDetails.height },
          { imageWidth: seo.opengraphImage?.mediaDetails.width },
        ]
    }
      />
      <Container fluid className="container-global-styling water-ex-post-container custom-max-height">
        <ExerciseList acfExerciseContent={acfExerciseContent} />
      </Container>
    </Layout>
  );
};
export default wpWaterExercisesPost;

export const query = graphql`
  query($id: String) {
    wpWaterExercisesPost(id: { eq: $id }) {
      id  
      title
      content
      acfExerciseContent {
        exerciseContent {
          name
          description
          time
          materials
          preparation
          procedure
          questions
          additionalInformation
          references
        }
        chapterLinkLabelText
        chapterLink {
            ... on WpWaterPost {
            uri
          }
        }
      }
      translations {
        language {
          locale
          name
        }
        uri
      }
      language {
          locale
          name
      }
      seo {
          opengraphUrl
          opengraphSiteName
          opengraphType
          opengraphTitle
          opengraphImage {
            link
            mediaDetails {
              height
              width
            }
          }
         }
    }
  }
`;
